<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-account-clock-outline
        </v-icon>Work Hours
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1 text-center">
            <p class="my-p-style">
              Set App Users Work Hours <br><br>
              <span class="wrk-hrs-sub">NB:- Set the work hours of all app users to a default value from this page. Individual work hours can be set from app user edit page.</span>
            </p>
          </v-card-text>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="appUsersWorkHours"
                label="Set Work Hours*"
                dense
                type="number"
                outlined
                :rules="[(v) => !!v || 'This field is required']"
                single-line
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-autocomplete
                v-model="selectedCompanies"
                class="field-style"
                :items="companiesList"
                item-text="name"
                item-value="id"
                hint="If no companies selected, work hours will get set to all app users in the list."
                persistent-hint
                label="Company*"
                single-line
                append-icon="mdi-office-building-plus-outline"
                outlined
                multiple
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="setAppUsersWorkHours()"
            >
              Set
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'SetAppUsersWorkHours',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        appUsersWorkHours: null,
        loading: false,
        valid: false,
        selectedCompanies: [],
      };
    },
    computed: {
        companiesList () {
            return this.$store.getters['companies/getCompaniesList'];
        },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      async setAppUsersWorkHours () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('appusers/setAppUsersWorkHours', {
          work_hours: parseInt(this.appUsersWorkHours),
          companies: this.selectedCompanies,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'App users work hours set successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('success');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },

    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #FF3700;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.wrk-hrs-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.field-style {
  margin-top: -20px;
}
</style>
