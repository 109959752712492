<template>
  <div>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              append-icon="mdi-magnify"
              outlined
              persistent-hint
              dense
              @keyup.enter="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
              @keydown.esc="getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="4"
            md="2"
            sm="1"
            class="text-right"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="getAllRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-file-multiple-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>All App Users</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="11"
          >
            <v-autocomplete
              v-model="selectedCompany"
              :items="companiesList"
              item-text="name"
              item-value="id"
              label="Company*"
              single-line
              append-icon="mdi-office-building-plus-outline"
              outlined
              dense
              @input="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="appUsers"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30]
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1"
        mobile-breakpoint="100"
        light
        @pagination="getRecordsFromServer"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>{{ item.phone_number }}  </td>
            <td>
              <span v-if="item.company !== null">{{ item.company.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span
                v-if="item.app_user_role !== null"
                class="type-style"
              > {{ item.app_user_role }} </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              <span v-if="item.worker_category !== null">{{ item.worker_category.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ item.work_hours }} Hrs</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              {{ item.request_status }}
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="updateAppUserDetails(item.external_id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
                :offset-x="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    fab
                    small
                    color="transparent"
                    elevation="0"
                    v-on="on"
                  >
                    <v-icon color="black">
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>

                <v-list class="listing-buttons">
                  <v-list-item
                    link
                    @click="deleteAppUser(item.external_id)"
                  >
                    <v-icon color="#C62828">
                      mdi-trash-can-outline
                    </v-icon>
                    Delete
                  </v-list-item>
                  <v-list-item
                    v-if="item.request_status === 'waiting' && item.created_by === 'contractor'"
                    link
                    @click="confirmAppUser(item, 'pending')"
                  >
                    <v-icon>mdi-account-check-outline</v-icon>
                    Accept
                  </v-list-item>
                  <v-list-item
                    v-if="item.request_status === 'waiting' && item.created_by === 'contractor'"
                    link
                    @click="confirmAppUser(item, 'declined')"
                  >
                    <v-icon>mdi-account-remove-outline</v-icon>
                    Decline
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <confirm-app-user
      v-if="showConfirmAppUser"
      :app-user-id="appUserId"
      :request-status="requestStatus"
      @closed="showConfirmAppUser = false"
      @success="confirmStatus()"
    />
    <edit-app-user
      v-if="showEditForm"
      :app-user-id="appUserId"
      @closed="showEditForm = false"
      @success="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
    />
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this app user ?'"
      :message="'Action will permanently remove the  user from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>
    <script>
      import spinner from 'src/views/dashboard/component/SpinnerCentre';
      import moment from 'moment';
      import EditAppUser from 'src/views/dashboard/appusers/EditAppUser';
      import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
      import ConfirmAppUser from './ConfirmAppUser.vue';
      import Constants from 'src/constants';

      export default {
        name: 'AppUsersPending',
        components: {
          'centre-spinner': spinner,
          'confirm-app-user': ConfirmAppUser,
          'edit-app-user': EditAppUser,
          'delete-app-user': DeleteDialog,
        },
        filters: {
          arrangePretty (val) {
            return val.join(', ');
          },
          convertToLocal (stringDatetime) {
            return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        data () {
          return {
            search: null,
            showForm: false,
            loading: false,
            headers: [
              { text: 'Name', align: 'start', value: 'first_name' },
              { text: 'Phone', value: 'mobile_number' },
              { text: 'Company', value: 'company' },
              { text: 'Role', value: 'role' },
              { text: 'Category', value: 'worker_category' },
              { text: 'Work Hours', value: 'work_hours' },
              { text: 'Added On', value: 'created_at' },
              { text: 'Status', value: 'status' },
              { text: 'Edit', value: 'edit' },
              { text: 'More', value: 'more' },
            ],
            showEditForm: false,
            appUserId: '',
            showDelete: false,
            selectedCompany: null,
            updateProgress: false,
            showConfirmAppUser: false,
            requestStatus: '',
            selectedAppUserDetails: {},
          };
        },
        computed: {
          companiesList () {
            return this.$store.getters['companies/getCompaniesList'];
          },
          paginatedAppUsersStructure () {
            return this.$store.getters['appusers/getPendingAppUsers'];
          },
          appUsers () {
            const records = this.paginatedAppUsersStructure;
            return records.data;
          },
          totalRecords () {
            if (Object.keys(this.paginatedAppUsersStructure).length > 0) {
              return this.paginatedAppUsersStructure.total;
            }
            return 0;
          },
          isSearchAdded () {
            if (this.search === null || this.search === '') {
              return false;
            }
            return true;
          },
          searchHint () {
            return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
          },
        },
        watch: {
          isSearchAdded (val) {
            if (val === false) {
              this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
            }
          },
        },
        async mounted () {
          await this.getCompaniesList();
        },
        methods: {
          async getAllRecordsFromServer ({ itemsPerPage, pageStart }) {
            this.updateProgress = true;
            this.search = null;
            this.selectedCompany = null;
            await this.$store.dispatch('appusers/fetchPendingAppUsers', {
              params: {
                params: {
                  limit: itemsPerPage,
                  startPage: pageStart,
                },
              },
            }).catch(() => {
              this.updateProgress = false;
            });
            this.updateProgress = false;
          },
          async getRecordsFromServer ({ itemsPerPage, pageStart }) {
            this.updateProgress = true;
            await this.$store.dispatch('appusers/fetchPendingAppUsers', {
              params: {
                params: {
                  name: this.search === '' ? null : this.search,
                  companyId: this.selectedCompany,
                  limit: itemsPerPage,
                  startPage: pageStart,
                },
              },
            }).catch(() => {
              this.updateProgress = false;
            });
            this.updateProgress = false;
          },
          async getCompaniesList () {
            this.loading = true;
            await this.$store.dispatch('companies/fetchCompaniesList').catch(() => {
              this.loading = false;
            });
            this.loading = false;
          },
          updateAppUserDetails (id) {
            this.appUserId = id;
            this.showEditForm = true;
          },
          deleteAppUser (id) {
            this.appUserId = id;
            this.showDelete = true;
          },
          deleteConfirmed () {
            this.loading = true;
            this.$store.dispatch('appusers/deleteAppUser', {
              appUserId: this.appUserId,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'Cancelled App user Request.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
              this.showDelete = false;
              this.loading = false;
              this.getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
            }).catch(() => {
              this.loading = false;
            });
          },
          confirmAppUser (data, status) {
            this.appUserId = data.external_id;
            this.selectedAppUserDetails = data;
            this.requestStatus = status;
            this.showConfirmAppUser = true;
          },
           async confirmStatus () {
            this.loading = true;
            await this.$store.dispatch('appusers/setAppUserPendingStatus', {
                status: {
                  request_status: this.requestStatus,
                },
                appUserOrgId: this.selectedAppUserDetails.app_user_organization_id,
            });
            await this.getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
            this.loading = false;
          },
        },
      };
    </script>
  <style scoped>
    .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: #CFD8DC
  }
  .v-data-table { background-color: #ECEFF1; }
  .title-style {
    color: #37474F;
  }
  .icon-style {
    color: #37474F;
    font-size: 45px;
  }
  .type-style {
    text-transform: capitalize;
  }
  .all-users-btn {
    margin-bottom: 25px;
  }
  .wrk-hr-btn {
    margin-right: 15px;
  }
  .v-card{
  margin-top: 0px!important;
  }
  .button-group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .v-list {
    padding: 0px 0px;
  }
  .listing-buttons .v-icon.v-icon {
    padding-right: 10px;
  }
    </style>
